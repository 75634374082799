<template>
  <div  id="app">
        <router-view name="header"></router-view>
        <main>
            <router-view/>
        </main>
        <router-view name="footer"></router-view>
        <notifications group="errors" classes="vue-notification text-dark" :reverse="true"/>
        <notifications group="successNotifications" classes="vue-notification text-dark" :reverse="true"/>
  </div>
</template>

<script>
/*eslint-disable*/
import Vue from "vue";
import Vuex from "vuex";

// import Map from './components/Map.vue'
// import BasicMap from './components/BasicMap.vue'
// import SideMenu from './components/SideMenu.vue'
// import NewDiscussion from './components/NewDiscussion.vue'
// import FlexContainer from './components/FlexContainer.vue'
// import FlexContainerNew from './components/FlexContainerNew.vue'

// import DiscussionList from './components/DiscussionList.vue'

import mainStateModule from "./state/modules/mainStateModule.js"
import mapStateModule from "./state/modules/mapStateModule.js"
import { loadModules } from 'esri-loader';

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    mapModule: mapStateModule,
    mainModule: mainStateModule
  },
  strict: debug
})

export default {
  components: {
  },
  store: store,
  data() {
    return {
        portalURL: "https://aus.gis.arcadis.com/portal",
        appId: '1fUJKbJryT5viyan',
    }
  },
  mounted () {
    const current = this
    // current.$store.dispatch("mapModule/fetchCRAEntries")
    // current.$store.dispatch("mainModule/fetchProjects")
    // current.$store.dispatch("mapModule/fetchPrimaryAssetTypes")
    // current.$store.dispatch("mapModule/fetchClimateHazards")
    // current.$store.dispatch("mapModule/fetchConsequenceCategories")
    // current.$store.dispatch("mapModule/fetchAssessmentConsequence")
    // current.$store.dispatch("mapModule/fetchAssessmentLikelihood")
    // current.$store.dispatch("mapModule/fetchAssessmentRatings")
    // current.$store.dispatch("mapModule/fetchRiskOwners")
    loadModules([
        "esri/config",
        "esri/identity/IdentityManager",
        "esri/identity/OAuthInfo",
        "esri/portal/Portal"
    ],
    { css: true })
    .then(([
            esriConfig,
            esriId,
            OAuthInfo,
            Portal
        ]) => {
            esriConfig.portalUrl = this.portalURL
            var authInfo = new OAuthInfo({
                appId: this.appId,
                portalUrl: this.portalURL,
                popup: false
            });
            esriId.registerOAuthInfos([authInfo]);
            var portal = new Portal({
                url: this.portalURL
            });
            portal.authMode = "immediate";

            esriId
            .checkSignInStatus(current.portalURL + "/sharing/rest")
              .then(() => {
                // console.log("checkSignInStatus")
              })
              .catch((e) => {
                // console.log("catch", e)
              });

            portal.load().then(function(portalResult) {
                // console.log('portal loaded');
                current.$store.commit("mapModule/setESRIPortalObject", portal)
                // console.log('portal saved');
                current.$store.dispatch("mapModule/fetchCRAEntries")
                current.$store.dispatch("mainModule/fetchProjects")
                current.$store.dispatch("mapModule/fetchPrimaryAssetTypes")
                current.$store.dispatch("mapModule/fetchClimateHazards")
                current.$store.dispatch("mapModule/fetchConsequenceCategories")
                current.$store.dispatch("mapModule/fetchAssessmentConsequence")
                current.$store.dispatch("mapModule/fetchAssessmentLikelihood")
                current.$store.dispatch("mapModule/fetchAssessmentRatings")
                current.$store.dispatch("mapModule/fetchRiskOwners")
                current.$store.dispatch("mapModule/fetchAdaptationTypes")
            });
            
        })
  },
};
</script>

<style>
.esri-icon-non-visible::before {
  content: "\e610" !important;
}
.esri-icon-visible::before {
  content: "\e611" !important;
}
.displayNone {
  display:none;
}
.sideContainer {
  /* border-style: ridge; */
  padding-left: 0!important;
  padding-right: 0!important;
}
div#mapContainer {
  /* padding-left: 0!important; */
  padding-right: 0!important;
}
/* html,body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
} */
#app {
  /* overflow: hidden; */
  /* display: flex;
  flex-direction: column; */
  /* padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; */
  height:98vh!important;
  overflow-x: hidden;
}
#sidebarContainer {
    overflow-y:scroll;
    overflow-x: hidden;
}
#headerLabel {
    align: right
}
#craStageSelector {
    z-index: 99999;
}
.bg-orange-custom {
    background-color: #E66200 !important;
  }
.custom-button-orange {
    color: #E66200!important;
    border-color: #E66200!important;
}
.custom-button-orange:hover .custom-button-orange:active{
    color: #fff;
    background-color: #E66200!important;
    border-color: #E66200!important;
}
#climateAssetContextMainPage {
    min-height: 93vh;
    min-width: 100vw;
    /* background-color: green; */
}
/* #dashboardImg {
    background-image: url("/img/MockDashboard.png");
    background-color: #ffffff;
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    height: 40vh;
    width: 100%;
} */
.mapsection {
    height: 34vh !important;
    /* width: 100%; */
    background-image: url("/img/MockMap.png");
    background-color: #ffffff;
    background-repeat:no-repeat;
    background-size:cover;
    /* background-position:center; */
    /* background-color: blue; */
}
</style>
