<template>
  <div>
    <!-- <div class="row">
      <div class="col-10"></div>
      <div class="col-2">
        Risk Counter
        <h5><b-badge variant="danger">11</b-badge></h5>
      </div>
    </div> -->
    <div class="row">
        <div class="col-10">
            <b-card
                title=""
                class="m-2 p-0"
                no-body>
                <template #header>
                    <h1 class="mb-0">{{projectName.toUpperCase()}}</h1>
                </template>
            </b-card>
        </div>
        <div class='col-2'>
            <div class="row">
                <div class="col-2 mt-4 pt-3 mb-4">
                  <b-button variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Fill Attendees" v-b-modal.modal-center @click="openAttendeesModalFlag = true"><i class="fa fa-address-card"></i></b-button>
                </div>
                <div class="col-4 text-center">
                    <h5 class="mt-4 pt-3 mb-4">Status: </h5>
                </div>
                <div class="col-6 mt-2 mb-2">
                    <b-form-radio-group
                        id="btn-radios-2"
                        v-model="currentStage4Status"
                        button-variant="text-dark"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                        stacked
                    >
                        <b-form-radio value="" class="btn-outline-danger">Not Started</b-form-radio>
                        <b-form-radio value="in_progress" class="btn-outline-warning">In Progress</b-form-radio>
                        <b-form-radio value="completed" class="btn-outline-success">Completed</b-form-radio>
                    </b-form-radio-group>
                </div>
            </div>
        </div>
    </div>
    <b-table-simple sticky-header small responsive>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>

      <!-- <colgroup><col><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col><col></colgroup> -->
      
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="table-th-border-custom align-middle" rowspan="3">Asset Type</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="3">Risk Description</b-th>
          <!-- <b-th class="table-th-border-custom align-middle" rowspan="2">Control measures currently in the design</b-th> -->
          <b-th class="table-th-border-custom align-middle" rowspan="3">Climate Hazards</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="3">Phase</b-th>
          <b-th class="table-th-border-custom text-center" colspan="3">Risk Ratings</b-th>
          <b-th class="table-th-border-custom align-middle" rowspan="3">Proposed adaptation actions</b-th>
          <b-th class="table-th-border-custom-left align-middle" colspan="3"></b-th>
          <b-th class="align-middle" colspan="3">Residual risk ratings</b-th>
          <b-th class="table-th-border-custom-right align-middle" colspan="3"></b-th>
          <!-- <b-th class="table-th-border-custom align-middle" rowspan="3">Implemented</b-th> -->
          <b-th class="table-th-border-custom align-middle" rowspan="3">Actions</b-th>
        </b-tr>
        
        <b-tr>
          <b-th class="table-th-border-custom" rowspan="2">2030</b-th>
          <b-th class="table-th-border-custom" rowspan="2">2050</b-th>
          <b-th class="table-th-border-custom" rowspan="2">2090</b-th>

          <!-- <b-th class="table-th-border-custom" rowspan="2">Engineering/design</b-th>
          <b-th class="table-th-border-custom" rowspan="2">Soft/management</b-th> -->

          <!-- <b-th class="table-th-border-custom" rowspan="2">2030</b-th>
          <b-th class="table-th-border-custom" rowspan="2">2050</b-th>
          <b-th class="table-th-border-custom" rowspan="2">2090</b-th> -->
          <b-th class="table-th-border-custom" colspan="3">2030</b-th>
          <b-th class="table-th-border-custom" colspan="3">2050</b-th>
          <b-th class="table-th-border-custom" colspan="3">2090</b-th>
        </b-tr>
        <b-tr>
          <b-th class="table-th-border-custom">L</b-th>
          <b-th class="table-th-border-custom">C</b-th>
          <b-th class="table-th-border-custom">R</b-th>
          <b-th class="table-th-border-custom">L</b-th>
          <b-th class="table-th-border-custom">C</b-th>
          <b-th class="table-th-border-custom">R</b-th>
          <b-th class="table-th-border-custom">L</b-th>
          <b-th class="table-th-border-custom">C</b-th>
          <b-th class="table-th-border-custom">R</b-th>
        </b-tr>
        <tr>
          <!-- <b-th>
            <button class="btn btn-outline-secondary btn-sm" type="button" v-on:click="clearAllFilters()"><i class="fa fa-eraser" ></i></button>
          </b-th> -->
          <!-- <b-th>
            <select class="form-control form-control-sm" v-model="selectedPrimaryAssetType">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in primaryAssetTypeFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="riskDescriptionString">
          </b-th>
          <b-th>
            <input class="form-control form-control-sm" type="text" v-model="designControlMeasureString">
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedNFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedMFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFLikelihood">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentLikelihoodFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFConsequence">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentConsequenceFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedFFRiskScore">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in assessmentRatingFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th>
            <select class="form-control form-control-sm" v-model="selectedRiskOwner">
              <option value="All" selected>All</option>
              <option v-for="(item, index) in riskOwnerFilterSet" :value="item" :key="index">{{item}}</option>
            </select>
          </b-th>
          <b-th></b-th> -->
        </tr>
      </b-thead>
      <b-tbody>
        <template v-if="filteredList.length">
          <b-tr v-for="item in craEntryListFinal" :key="item.objectId">
            <b-td>
              <span>{{ item.primaryAssetTypeLabel }}</span>
            </b-td>
            <b-td>
              <span>{{ item.risk_description }}</span>
            </b-td>
            <b-td>
              <span>{{ item.climateHazardLabel }}</span>
            </b-td>
            <b-td>
              <span>{{ item.riskOwnerLabel }}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.riskRatingNFCode=='very_high'?'danger':(item.riskRatingNFCode=='high'?'orange':(item.riskRatingNFCode=='medium'?'warning':(item.riskRatingNFCode=='low'?'success':'secondary'))))">{{item.riskRatingNFLabel}}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.riskRatingMFCode=='very_high'?'danger':(item.riskRatingMFCode=='high'?'orange':(item.riskRatingMFCode=='medium'?'warning':(item.riskRatingMFCode=='low'?'success':'secondary'))))">{{item.riskRatingMFLabel}}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.riskRatingFFCode=='very_high'?'danger':(item.riskRatingFFCode=='high'?'orange':(item.riskRatingFFCode=='medium'?'warning':(item.riskRatingFFCode=='low'?'success':'secondary'))))">{{item.riskRatingFFLabel}}</span>
            </b-td>
            <!-- Editable fields -->
            <b-td>
              <!-- <textarea v-if="item.isEdit" style="resize:none;" rows="2" class="form-control" v-model="item.adaptation_engineering_design"></textarea>
              <span v-else>{{ item.adaptation_engineering_design }}</span> -->
              <template v-if="item.adaptationActions.length == 0">
                <b-button class="m-1" variant="outline-primary" size="sm" v-b-tooltip.hover.left title="Add New Adaptation Actions" @click="addAdaptationAction(item.id)"><i class="fa fa-plus"></i></b-button>
              </template>
              <template v-else>
                <div class="row ml-1" v-for="adaptIdx in Math.ceil(item.adaptationActions.length / 3)" :key="adaptIdx">
                    <div class="" v-for="(adaptItem, adaptIndex) in item.adaptationActions.slice(3 * (adaptIdx - 1), 3 * adaptIdx)" :key="adaptIndex">
                      <b-button class="m-1" :variant="adaptItem.is_implemented?'outline-success':'outline-warning'" size="sm" v-b-tooltip.hover.left title="Adaptation Action Details" @click="viewAdaptationAction(item.id,adaptIndex + 3 * (adaptIdx - 1) + 1,adaptItem)">{{ adaptIndex + 3 * (adaptIdx - 1) + 1 }}</b-button>
                      <template v-if="(item.adaptationActions.length == (adaptIndex + 3 * (adaptIdx - 1) + 1) && item.adaptationActions.length < 9)">
                        <b-button class="m-1" variant="outline-primary" size="sm" v-b-tooltip.hover.left title="Add New Adaptation Actions" @click="addAdaptationAction(item.id)"><i class="fa fa-plus"></i></b-button>
                      </template>
                    </div>
                </div>
              </template>
            </b-td>
            <!-- <b-td>
              <textarea v-if="item.isEdit" style="resize:none;" rows="2" class="form-control" v-model="item.adaptation_soft_management"></textarea>
              <span v-else>{{ item.adaptation_soft_management }}</span>
            </b-td> -->
            <!-- NF -->
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentLikelihoodNFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentLikelihoodNFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentConsequenceNFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentConsequenceNFLabel}}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.residualRiskRatingNFCode=='very_high'?'danger':(item.residualRiskRatingNFCode=='high'?'orange':(item.residualRiskRatingNFCode=='medium'?'warning':(item.residualRiskRatingNFCode=='low'?'success':'secondary'))))">{{item.residualRiskRatingNFLabel}}</span>
            </b-td>
            <!-- MF -->
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentLikelihoodMFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentLikelihoodMFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentConsequenceMFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentConsequenceMFLabel}}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.residualRiskRatingMFCode=='very_high'?'danger':(item.residualRiskRatingMFCode=='high'?'orange':(item.residualRiskRatingMFCode=='medium'?'warning':(item.residualRiskRatingMFCode=='low'?'success':'secondary'))))">{{item.residualRiskRatingMFLabel}}</span>
            </b-td>
            <!-- FF -->
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentLikelihoodFFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentLikelihood in assessmentLikelihoodOptions"  :key="assessmentLikelihood.id" :value="assessmentLikelihood.code">{{assessmentLikelihood.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentLikelihoodFFLabel}}</span>
            </b-td>
            <b-td>
              <select class="form-control" v-model="item.residualRiskAssessmentConsequenceFFCode" v-if="item.isEdit">
                <option value="" disabled selected></option>
                <option v-for="assessmentConsequence in assessmentConsequenceOptions"  :key="assessmentConsequence.id" :value="assessmentConsequence.code">{{assessmentConsequence.label}}</option>
              </select>
              <span v-else>{{item.residualRiskAssessmentConsequenceFFLabel}}</span>
            </b-td>
            <b-td>
              <span :class="'text-'+(item.residualRiskRatingFFCode=='very_high'?'danger':(item.residualRiskRatingFFCode=='high'?'orange':(item.residualRiskRatingFFCode=='medium'?'warning':(item.residualRiskRatingFFCode=='low'?'success':'secondary'))))">{{item.residualRiskRatingFFLabel}}</span>
            </b-td>
            <!-- <b-td>
              <b-form-checkbox
                v-model="item.is_action_implemented"
                value="1"
                unchecked-value="0"
                :disabled="!item.isEdit"
              >
              </b-form-checkbox>
            </b-td> -->

            <b-td>
              <div class="d-flex justify-content-end">
                  <span :class="'p-1 '+(item.isEdit==true?'hideCustom':'')">
                    <b-button variant="outline-primary" size="sm" @click="editRowHandler(item)" v-b-tooltip.hover.left title="Edit entry">
                      <i class="fa fa-pen"></i>
                    </b-button>
                  </span>
                  <span class="p-1" v-if="item.isEdit">
                    <b-button variant="outline-danger" size="sm" v-b-tooltip.hover.left title="Cancel edit entry" @click="editRowHandler(item)"><i class="fa fa-times"></i></b-button>
                  </span>
                  <span class="p-1" v-if="item.isEdit">
                    <b-button variant="outline-success" size="sm" v-b-tooltip.hover.bottom title="Save entry" @click="saveRowHandler(item)"><i class="fa fa-save"></i></b-button>
                  </span>
                  <span class="p-1">
                    <b-button variant="outline-danger" size="sm" @click="deleteRowHandler(item)" v-b-tooltip.hover.bottom title="Delete entry"><i class="fa fa-trash"></i></b-button>
                  </span>
              </div>
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <div class="">
              No data found.
            </div>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        
      </b-tfoot>
    </b-table-simple>
    <div class="pr-5 pl-3">
      <paginate
        v-if="filteredList.length > 0"
        v-model="selectedPageNumber"
        :page-count="numberOfPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="selectNewPage"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination pagination-sm'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
      >
      </paginate>
      
    </div>
    <button type="button" class="btn btn-secondary btn-circle btn-xl bottomright btn-orange" v-on:click="exportReport()" title="Export Report"><i class="fa fa-download"></i></button>
    <b-modal v-model="openEngDesAdaptationModalFlag" size="lg" :title="'Adaptation Action ' + (adaptationItemId?'#'+adaptationItemId:'')" title-tag="h4">
      <!-- <template #modal-header>
        <h4>Adaptation Action {{ adaptationItemId?"#"+adaptationItemId:"" }}</h4>
      </template> -->
      
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Adaptation type</label>
        <div class="col-sm-8">
          <select class="form-control" v-model="adaptationTypeCode">
            <option value="" disabled selected></option>
            <option v-for="adaptationType in adaptationTypes"  :key="adaptationType.id" :value="adaptationType.code">{{adaptationType.label}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Owner Responsible</label>
        <div class="col-sm-8">
          <input class="form-control" v-model="ownerResponsible"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Proposed adaptation action details and design/operational plan reference</label>
        <div class="col-sm-8">
          <textarea style="resize:none;" rows="3" class="form-control" v-model="textAdaptActions" required></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Implementation timing</label>
        <div class="col-sm-8">
          <select class="form-control" v-model="selectedImplementationTiming">
            <option value="" disabled selected></option>
            <option v-for="(timingItem, timingIndex) in implementationTimingSet"  :key="timingIndex" :value="timingItem">{{timingItem}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Has the action been implemented? </label>
        <div class="col-sm-8">
          <b-form-checkbox
            v-model="isActionImplemented"
            value="1"
            unchecked-value="0"
          >
          </b-form-checkbox>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <span class="p-1">
            <b-button
              
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="openEngDesAdaptationModalFlag=false"
            >
              Cancel
            </b-button>
          </span>
          <span class="p-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="submitAdaptActions()"
            >
              Submit
            </b-button>
          </span>
        </div>
      </template>
    </b-modal>
    <b-modal 
      v-model="openAttendeesModalFlag" 
      size="lg" 
      :title='stageName+": Stakeholder Engagement Summary"' 
      title-tag="h4"
      @hidden="cancelAttendees">

      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Attendees</label>
        <div class="col-sm-8">
          <textarea rows="3" class="form-control" v-model="textAttendees" required></textarea>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-end">
          <span class="p-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="addAttendee()">
              Add
            </b-button>
          </span>
        </div>
      <br>
      <b-table-simple sticky-header small responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Attendees</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="stage4AttendeesList.length > 0 || projectAssessmentStakeholdersList.length > 0">
            <tr v-for="(stageItem, stageIndex) in stage4AttendeesList" :key="stageIndex">
              <td>{{stageItem.created_at?new Date(stageItem.created_at).toISOString().substring(0,10):stageItem.date}}</td>
              <td>{{stageItem.attendees}}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <div class="">
                No data found.
              </div>
            </tr>
          </template>
        </tbody>
      </b-table-simple>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <span class="p-1">
            <b-button
              
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="cancelAttendees()"
            >
              Cancel
            </b-button>
          </span>
          <span class="p-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="float-right"
              @click="saveAttendees()"
            >
              Save
            </b-button>
          </span>
        </div>
      </template>
    </b-modal>
  </div>
  
</template>

<script>
/*eslint-disable*/
import { baseApiUrl, appBaseUrl } from "../../config.js"
import axios from "axios"
import Paginate from 'vuejs-paginate'
import Multiselect from "vue-multiselect"
export default {
name: 'PreliminaryAssessment',
components: {
  Paginate,
  Multiselect
},
data() {
  return {
    openAttendeesModalFlag: false,
    textAttendees: "",
    openEngDesAdaptationModalFlag: false,
    disciplineFilterSet: ['Cost Estimation and Quantities','Environmental','Road Design','Civil','Steering Committee','Health and Safety','Utilities','Flooding','Structures','Geotechnical','Management','Independent Review','Traffic','Drainage','Pavements','GIS','Commercial','Drafting','Lighting'],
    statusFilterSet: ['Approved','Pending'],
    workPackageFilterSet: ['LH2RH','FB2L','RH2FB','CRR'],
    typeFilterSet: ['Constructability','Health & Safety','3D Model Discussion','Coordination','Design Discussion'],
    priorityFilterSet: ['Critical', 'Major', 'Minor'],
    riskRatingFilterSet: ['A - Very High','B - High','C - Medium','D - Low','-'],
    selectedConsequenceCategory: "All",
    selectedPrimaryAssetType: "All",
    selectedAssetSubType: "All",
    selectedRiskType: "All",
    selectedClimateHazard: "All",
    selectedRiskOwner: "All",
    selectedMateriality: "All",
    riskDescriptionString: "",
    designControlMeasureString: "",
    selectedNFRiskScore: "All",
    selectedMFRiskScore: "All",
    selectedFFRiskScore: "All",
    selectedNFLikelihood: "All",
    selectedMFLikelihood: "All",
    selectedFFLikelihood: "All",
    selectedNFConsequence: "All",
    selectedMFConsequence: "All",
    selectedFFConsequence: "All",
    appBaseUrl: appBaseUrl,
    searchCreatedBy: "",
    searchAddressedTo: "",
    attachmentNameLengthLimit: 15,
    // numberOfPages: 0,
    perPageCount: 30,
    selectedPageNumber: 1,
    isCRAEditEnabled: false,
    preAssessmentItems: [],
    currentCRAEntry: [],
    currentStage4Status: '',
    addressedTo: [],
    textAdaptActions:'',
    ownerResponsible: '',
    isActionImplemented: 0,
    adaptationTypeCode: '',
    implementationTimingSet: ['Design','Construction','2030','2050','2070','2090'],
    selectedImplementationTiming: '',
    currentAdaptationAction: null,
    currentCRAEntryID: null,
    currentAdaptationActionID: null,
    adaptationItemId: null,
    errorMsgDictionary: {
      noAdaptationType: "Adaptation Type field was left blank",
      noOwnerResponsible: "Owner Responsible field was left blank",
      noAdaptationActions: "Proposed adaptation action details and design/operational plan reference field was left blank",
      noImplementationTiming: "Implementation timing field was left blank",
    },
    stage4AttendeesList: []
  }
},
computed: {
  projectName: {
      get(){
          this.currentStage4Status = JSON.parse(JSON.stringify(this.$store.state.mainModule.currentProject.stage_4_status));
          return this.$store.state.mainModule.currentProject.name
      }
  },
  projectCode: {
      get(){
          return this.$store.state.mainModule.currentProject.code
      }
  },
  primaryAssetTypeOptions: {
      get() {
          return this.$store.state.mapModule.primaryAssetTypeList.filter(obj=>obj.isActive == true)
      }
  },
  assessmentLikelihoodOptions: {
      get() {
          return this.$store.state.mapModule.assessmentLikelihoodList
      }
  },
  assessmentConsequenceOptions: {
      get() {
          return this.$store.state.mapModule.assessmentConsequencesList
      }
  },
  assessmentRatingOptions: {
      get() {
          return this.$store.state.mapModule.assessmentRatingList
      }
  },
  riskOwnerOptions: {
      get() {
          return this.$store.state.mapModule.riskOwnersList
      }
  },
  discussionList: {
    get() {
      return this.$store.state.mapModule.initialDiscussions
    }
  },
  projectId: {
      get(){
          return this.$store.state.mainModule.currentProjectId
      }
  },
  craEntryList: {
      get() {
        var output = {}
        var is2090 = false
        var riskCodes = ['medium','very_high']
        var projectAssetCriticality = this.$store.state.mainModule.currentProjectAssetCriticalityList.filter(obj=>obj.yes==true).map(item=>item.label)
        
        if (projectAssetCriticality.includes("Is the project's corridor a major freight route (e.g. high volume)?") || projectAssetCriticality.includes("Is the project's corridor a major evacuation route?") || projectAssetCriticality.includes("Is the project's corridor a major emergency response route?") || projectAssetCriticality.includes("Is the project's corridor a major detour route during network disruptions of disaster recovery?")) {
            riskCodes.push('high')
        }
        if (projectAssetCriticality.includes(["Is the project's design life more than 40 years?"])) {
            is2090 = true
        }
        if (!projectAssetCriticality.includes(["Is the corridor likely to require significant renewal and/or modification prior to or by 2050?"])) {
            is2090 = true
        }

        if(is2090) {
            output = this.$store.state.mapModule.currentProjectCRAEntryList.filter(
                  obj=>riskCodes.includes(obj.riskRatingNFCode) ||
                  riskCodes.includes(obj.riskRatingMFCode) ||
                  riskCodes.includes(obj.riskRatingFFCode)
                )
        }
        else {
            output = this.$store.state.mapModule.currentProjectCRAEntryList.filter(
                  obj=>riskCodes.includes(obj.riskRatingNFCode) ||
                  riskCodes.includes(obj.riskRatingMFCode) 
                )
        }
        return output
          
      },
      set(value){
        this.preAssessmentItems = value
      }
  },
  primaryAssetTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.primaryAssetTypeList.map(a => a.label);
      }
  },
  climateHazardOptions: {
      get() {
          return this.$store.state.mapModule.climateHazardList
      }
  },
  assetSubTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.assetSubTypeList.map(a => a.label);
      }
  },
  climateHazardFilterSet: {
      get() {
          return this.$store.state.mapModule.climateHazardList.map(a => a.label);
      }
  },
  riskTypeFilterSet: {
      get() {
          return this.$store.state.mapModule.riskTypesList.map(a => a.label);
      }
  },
  materialityFilterSet: {
      get() {
          return this.$store.state.mapModule.materialityList.map(a => a.label);
      }
  },
  consequenceCategoryFilterSet: {
      get() {
          return this.$store.state.mapModule.consequenceCategoriesList.map(a => a.label);
      }
  },
  riskOwnerFilterSet: {
      get() {
          return this.$store.state.mapModule.riskOwnersList.map(a => a.label);
      }
  },
  assessmentLikelihoodFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentLikelihoodList.map(a => a.label);
      }
  },
  assessmentConsequenceFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentConsequencesList.map(a => a.label);
      }
  },
  assessmentRatingFilterSet: {
      get() {
          return this.$store.state.mapModule.assessmentRatingList.map(a => a.label);
      }
  },
  projectAssessmentStakeholdersList: {
      get() {
        this.stage4AttendeesList = this.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_4");
        return this.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_4");
      }
  },
  stageName: {
      get() {
        return this.$store.state.mainModule.assessmentStagesList.filter(obj => obj.code == "stage_4")[0].name;
      }
  },
  filteredList: {
    get() {

      var finalList = []
      // this.currentCRAEntry.forEach(item=>{
      //     // finalList.push(item.entries)
      //     item.entries.forEach(entry=>{
      //         entry.projectName = item.project.name
      //         entry.isEditable = false
      //         finalList.push(entry)
      //     })
      // })
      // this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}  
      // console.log(this.currentCRAEntry)
      // need to add filter block on this to accommodate task 14 of the final CRA revisions

      this.craEntryList.forEach(item=>{
          finalList.push(item)
      })
      if (this.selectedPrimaryAssetType != "All") {
        finalList = finalList.filter(obj=>obj.primaryAssetTypeLabel.includes(this.selectedPrimaryAssetType))
      }
      if (this.selectedAssetSubType != "All") {
        finalList = finalList.filter(obj=>obj.assetSubTypeLabel == this.selectedAssetSubType)
      }
      if (this.selectedClimateHazard != "All") {
        finalList = finalList.filter(obj=>obj.climateHazardLabel == this.selectedClimateHazard)
      }
      if (this.selectedRiskType != "All") {
        finalList = finalList.filter(obj=>obj.riskTypeLabel == this.selectedRiskType)
      }
      if (this.selectedConsequenceCategory != "All") {
        finalList = finalList.filter(obj=>obj.consequenceCategoryLabel == this.selectedConsequenceCategory)
      }
      if (this.selectedRiskOwner != "All") {
        finalList = finalList.filter(obj=>obj.riskOwnerLabel == this.selectedRiskOwner)
      }
      if (this.selectedMateriality != "All") {
        finalList = finalList.filter(obj=>obj.materialityLabel == this.selectedMateriality)
      }
      if (this.riskDescriptionString != "") {
        finalList = finalList.filter(obj=>obj.risk_description && obj.risk_description.toLowerCase().search(this.riskDescriptionString.toLowerCase())!=-1)
      }
      if (this.designControlMeasureString != "") {
        finalList = finalList.filter(obj=>obj.current_design_control_measure && obj.current_design_control_measure.toLowerCase().search(this.designControlMeasureString.toLowerCase())!=-1)
      }
      if (this.selectedNFRiskScore != "All") {
          if (this.selectedNFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingNFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingNFLabel == this.selectedNFRiskScore)
          }
      }
      if (this.selectedMFRiskScore != "All") {
          if (this.selectedMFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingMFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingMFLabel == this.selectedMFRiskScore)
          }
      }
      if (this.selectedFFRiskScore != "All") {
          if (this.selectedFFRiskScore =='-') {
              finalList = finalList.filter(obj=>obj.riskRatingFFLabel == "")
          }
          else {
              finalList = finalList.filter(obj=>obj.riskRatingFFLabel == this.selectedFFRiskScore)
          }
      }
      if (this.selectedNFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodNFLabel == this.selectedNFLikelihood)
      }
      if (this.selectedMFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodMFLabel == this.selectedMFLikelihood)
      }
      if (this.selectedFFLikelihood != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentLikelihoodFFLabel == this.selectedFFLikelihood)
      }
      if (this.selectedNFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceNFLabel == this.selectedNFConsequence)
      }
      if (this.selectedMFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceMFLabel == this.selectedMFConsequence)
      }
      if (this.selectedFFConsequence != "All") {
        finalList = finalList.filter(obj=>obj.riskAssessmentConsequenceFFLabel == this.selectedFFConsequence)
      }
      if (['climateAssetContext','preScreening'].includes(this.currentCRAStageCode)) {
          finalList.sort((a, b) => b.materiality_id - a.materiality_id);
      } else if (this.currentCRAStageCode == 'detailedRiskAssessment') {
          finalList.sort((a, b) => b.risk_rating_nf_id - a.risk_rating_nf_id||b.risk_rating_mf_id - a.risk_rating_mf_id||b.risk_rating_ff_id - a.risk_rating_ff_id);
      }
      // return finalList
      return finalList.map(item => ({...item, isEdit: false}))
    }
  },
  numberOfPages: {
    get() {
      return Math.ceil(this.filteredList.length / this.perPageCount)
    }
  },
  craEntryListFinal: {
    get() {
      const current = this
      current.preAssessmentItems = current.filteredList.map(item => ({...item, isEdit: false}))
      if(current.numberOfPages >= current.selectedPageNumber){
        return current.preAssessmentItems.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
      }
      else{
        current.selectedPageNumber = 1
        return current.preAssessmentItems.slice((current.selectedPageNumber - 1) * current.perPageCount, current.selectedPageNumber * current.perPageCount)
      }

    }
  },
  currentCRAStageCode: {
      get() {
          return this.$store.state.mapModule.selectedCRAStage.code
      }
  },
  adaptationTypes: {
      get() {
        return this.$store.state.mapModule.adaptationTypeList
      }
    },
},
watch: {
  currentStage4Status: function(newVal, oldVal) {
            if (newVal != this.$store.state.mainModule.currentProject.stage_4_status) {
                let data = {
                    projectId: this.$store.state.mainModule.currentProjectId,
                    stage: 'stageFour',
                    stageStatus: newVal
                }
                let url = baseApiUrl + 'projectStageStatus'
                let config  = {
                    responseType: 'json'
                }
                axios.put(url,data,config).then(()=>{
                    this.$notify({
                        group: 'successNotifications',
                        title: "Project Stage Four Status Update",
                        type: 'success',
                        text: "Update to the project stage four status completed.",
                        width: 500,
                        duration: 1500
                    })
                    // update project list and project details
                    this.$store.dispatch('mainModule/fetchProjects').then(()=>{
                        this.$store.commit('mainModule/setCurrentProject',this.$store.state.mainModule.currentProjectId)
                    })
                })
                .catch((err)=>{
                    this.$notify({
                        group: 'errors',
                        title: "No Update done",
                        type: 'error',
                        text: "No Update to the project stage four status completed",
                        width: 500,
                        duration: 1500
                    })
                })
            }
        },
  'craEntryListFinal': {
    handler (newValue, oldValue) {
      newValue.forEach((item) => {
        if (item.residualRiskAssessmentLikelihoodNFCode && item.residualRiskAssessmentConsequenceNFCode) {
                if (item.residualRiskAssessmentLikelihoodNFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.residualRiskRatingNFCode != 'low') {
                            item.residualRiskRatingNFCode = 'low'
                            item.residualRiskRatingNFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.residualRiskAssessmentLikelihoodNFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'high') {
                            item.residualRiskRatingNFCode = 'high'
                            item.residualRiskRatingNFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingNFCode != 'low') {
                            item.residualRiskRatingNFCode = 'low'
                            item.residualRiskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodNFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'high') {
                            item.residualRiskRatingNFCode = 'high'
                            item.residualRiskRatingNFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingNFCode != 'low') {
                            item.residualRiskRatingNFCode = 'low'
                            item.residualRiskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodNFCode == 'likely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'very_high') {
                            item.residualRiskRatingNFCode = 'very_high'
                            item.residualRiskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'high') {
                            item.residualRiskRatingNFCode = 'high'
                            item.residualRiskRatingNFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingNFCode != 'low') {
                            item.residualRiskRatingNFCode = 'low'
                            item.residualRiskRatingNFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodNFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'very_high') {
                            item.residualRiskRatingNFCode = 'very_high'
                            item.residualRiskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'high') {
                            item.residualRiskRatingNFCode = 'high'
                            item.residualRiskRatingNFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'very_high') {
                            item.residualRiskRatingNFCode = 'very_high'
                            item.residualRiskRatingNFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceNFCode)) {
                        if (item.residualRiskRatingNFCode != 'high') {
                            item.residualRiskRatingNFCode = 'high'
                            item.residualRiskRatingNFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingNFCode != 'medium') {
                            item.residualRiskRatingNFCode = 'medium'
                            item.residualRiskRatingNFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.residualRiskRatingNFCode) {
                    item.residualRiskRatingNFCode = ''
                    item.residualRiskRatingNFLabel = ''
                }
            }
            // MF autocalc section
            if (item.residualRiskAssessmentLikelihoodMFCode && item.residualRiskAssessmentConsequenceMFCode) {
                if (item.residualRiskAssessmentLikelihoodMFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.residualRiskRatingMFCode != 'low') {
                            item.residualRiskRatingMFCode = 'low'
                            item.residualRiskRatingMFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.residualRiskAssessmentLikelihoodMFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'high') {
                            item.residualRiskRatingMFCode = 'high'
                            item.residualRiskRatingMFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingMFCode != 'low') {
                            item.residualRiskRatingMFCode = 'low'
                            item.residualRiskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodMFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'high') {
                            item.residualRiskRatingMFCode = 'high'
                            item.residualRiskRatingMFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingMFCode != 'low') {
                            item.residualRiskRatingMFCode = 'low'
                            item.residualRiskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodMFCode == 'likely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'very_high') {
                            item.residualRiskRatingMFCode = 'very_high'
                            item.residualRiskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'high') {
                            item.residualRiskRatingMFCode = 'high'
                            item.residualRiskRatingMFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingMFCode != 'low') {
                            item.residualRiskRatingMFCode = 'low'
                            item.residualRiskRatingMFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodMFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'very_high') {
                            item.residualRiskRatingMFCode = 'very_high'
                            item.residualRiskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'high') {
                            item.residualRiskRatingMFCode = 'high'
                            item.residualRiskRatingMFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'very_high') {
                            item.residualRiskRatingMFCode = 'very_high'
                            item.residualRiskRatingMFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceMFCode)) {
                        if (item.residualRiskRatingMFCode != 'high') {
                            item.residualRiskRatingMFCode = 'high'
                            item.residualRiskRatingMFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingMFCode != 'medium') {
                            item.residualRiskRatingMFCode = 'medium'
                            item.residualRiskRatingMFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.residualRiskRatingMFCode) {
                    item.residualRiskRatingMFCode = ''
                    item.residualRiskRatingMFLabel = ''
                }
            }
            // FF  autocalc section
            if (item.residualRiskAssessmentLikelihoodFFCode && item.residualRiskAssessmentConsequenceFFCode) {
                if (item.residualRiskAssessmentLikelihoodFFCode == 'almost_unprecedented') {
                    if (['severe','catastrophic'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    } 
                    else {
                        if (item.residualRiskRatingFFCode != 'low') {
                            item.residualRiskRatingFFCode = 'low'
                            item.residualRiskRatingFFLabel = "D - Low"
                        }
                    }
                } 
                else if (item.residualRiskAssessmentLikelihoodFFCode == 'very_unlikely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'high') {
                            item.residualRiskRatingFFCode = 'high'
                            item.residualRiskRatingFFLabel = "B - High"

                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingFFCode != 'low') {
                            item.residualRiskRatingFFCode = 'low'
                            item.residualRiskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodFFCode == 'unlikely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'high') {
                            item.residualRiskRatingFFCode = 'high'
                            item.residualRiskRatingFFLabel = "B - High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingFFCode != 'low') {
                            item.residualRiskRatingFFCode = 'low'
                            item.residualRiskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodFFCode == 'likely') {
                    if (['catastrophic'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'very_high') {
                            item.residualRiskRatingFFCode = 'very_high'
                            item.residualRiskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['severe','major'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'high') {
                            item.residualRiskRatingFFCode = 'high'
                            item.residualRiskRatingFFLabel = "B - High"
                        }
                    }
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    }
                    else {
                        if (item.residualRiskRatingFFCode != 'low') {
                            item.residualRiskRatingFFCode = 'low'
                            item.residualRiskRatingFFLabel = "D - Low"
                        }
                    }
                }
                else if (item.residualRiskAssessmentLikelihoodFFCode == 'very_likely') {
                    if (['catastrophic', 'severe'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'very_high') {
                            item.residualRiskRatingFFCode = 'very_high'
                            item.residualRiskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','major'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'high') {
                            item.residualRiskRatingFFCode = 'high'
                            item.residualRiskRatingFFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    }
                }
                else {
                    if (['catastrophic', 'severe', 'major'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'very_high') {
                            item.residualRiskRatingFFCode = 'very_high'
                            item.residualRiskRatingFFLabel = "A - Very High"
                        }
                    } 
                    else if (['moderate','minor'].includes(item.residualRiskAssessmentConsequenceFFCode)) {
                        if (item.residualRiskRatingFFCode != 'high') {
                            item.residualRiskRatingFFCode = 'high'
                            item.residualRiskRatingFFLabel = "B - High"
                        }
                    }
                    else {
                        if (item.residualRiskRatingFFCode != 'medium') {
                            item.residualRiskRatingFFCode = 'medium'
                            item.residualRiskRatingFFLabel = 'C - Medium'
                        }
                    }
                }

            }
            else {
                if (item.residualRiskRatingFFCode) {
                    item.residualRiskRatingFFCode = ''
                    item.residualRiskRatingFFLabel = ''
                }
            }
      })
    },
    deep: true
  }
},
methods: {
  goToObject: function(id) {
    sessionStorage.origURL = appBaseUrl+id
  },
  downloadFile: function(fileId, type) {
    let formData = new FormData();
    let url = baseApiUrl + "downloadAttachment";
    let config = {
      headers: {
        'content-type': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    formData.append("fileId", fileId);
    formData.append("type", type);
    axios.post(url, formData, config).then((result) => {
      var disposition = result.headers['content-disposition'];
      // fix this matches
      var matches = /=(.*)/.exec(disposition);
      var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
      // filename = filename.replace(/"*/g,"");
      var type = result.headers['content-type'];
      var link = document.createElement('a');

      document.body.appendChild(link)
      link.style = "display: none"
      var blob = new Blob(
        [result.data],
        {type: result.headers['content-type']}
      );
      var url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
      // link.remove();
    });
  },
  selectNewPage: function(pageNumber) {
    const current = this
    current.selectedPageNumber = pageNumber
  },
  exportReport: function() {
    const current = this
    let formData = new FormData();
    formData.append('projectCode',current.projectCode)
    formData.append('reportType','adaptation_planning')
    let url = baseApiUrl + "exportCRAReport";
    let config = {
      headers: {
        'content-type': 'application/octet-stream'
      },
      responseType: 'blob'
    }
    axios.post(url, formData, config).then((result) => {
      var disposition = result.headers['content-disposition'];
      // fix this matches
      var matches = /=(.*)/.exec(disposition);
      var filename = (matches != null && matches[1] ? matches[1] : '').replace(/"*/g,"");
      // filename = filename.replace(/"*/g,"");
      var type = result.headers['content-type'];
      var link = document.createElement('a');

      document.body.appendChild(link)
      link.style = "display: none"
      var blob = new Blob(
        [result.data],
        {type: result.headers['content-type']}
      );
      var url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  },
  clearAllFilters: function() {
      this.selectedConsequenceCategory =  "All"
      this.selectedPrimaryAssetType =  "All"
      this.selectedAssetSubType =  "All"
      this.selectedRiskType =  "All"
      this.selectedClimateHazard =  "All"
      this.selectedRiskOwner =  "All"
      this.selectedMateriality =  "All"
      this.riskDescriptionString =  ""
  },
  selectCRAEntryFromList: function(craEntryId) {
      this.$store.dispatch('mapModule/setCurrentCRAEntryProcess', craEntryId).then(()=>{
          this.switchToFormMode()
      })
  },
  switchToFormMode: function() {
      // turn on
      this.$store.commit('mapModule/toggleIsViewDiscussions')
      var flexContainer = document.getElementById("flexContainer")
      var sidebarList = document.getElementById("craListSidebar")
      flexContainer.classList.remove("displayNone")
      sidebarList.classList.add("displayNone")
  },
  editRowHandler: function(data) {
    const current = this
    current.isCRAEditEnabled = !current.isCRAEditEnabled
    current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit = !current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit
    current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit = !current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit
  },
  saveRowHandler(data) {
    const current = this
    current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit = !current.filteredList.filter(obj=>obj.id == data.id)[0].isEdit
    var currentPreAssessItem = current.preAssessmentItems.filter(obj=>obj.id == data.id)[0]
    current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit = !current.preAssessmentItems.filter(obj=>obj.id == data.id)[0].isEdit
    currentPreAssessItem.riskAssessmentLikelihoodNFCode = data.riskAssessmentLikelihoodNFCode
    // current.currentCRAEntry.filter(obj=>obj.id == data.id)[0].isEdit = !current.currentCRAEntry.filter(obj=>obj.id == data.id)[0].isEdit
    this.$store.dispatch('mapModule/updatePreScreenCRAEntry', data).then(()=>{
      
        this.$store.dispatch('mapModule/updateRiskAssessmentCRAEntry', data).then(()=>{
          current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
            this.$store.commit('mapModule/setCurrentProjectCRAEntries', current.projectId)
          })
          this.$notify({
              group: 'successNotifications',
              title: "Success",
              type: 'success',
              text: "Successfully saved ",
              width: 500,
              duration: 3000
          })
          // this.$bvModal.hide("filterCRAModal")

        })
    })
  },
  deleteRowHandler(data) {
    const current = this
    this.$store.dispatch('mapModule/deleteCRAEntry', data).then(()=>{
        current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
            this.$notify({
                group: 'successNotifications',
                title: "Success",
                type: 'success',
                text: "Successfully deleted ",
                width: 500,
                duration: 3000
            })
        })
        
    })
  },
  addAdaptationAction(data){
    const current = this
    current.adaptationItemId = null
    current.currentAdaptationActionID = null
    current.adaptationTypeCode = ''
    current.textAdaptActions = ''
    current.ownerResponsible = ''
    current.selectedImplementationTiming = ''
    current.isActionImplemented = 0
    current.openEngDesAdaptationModalFlag = !current.openEngDesAdaptationModalFlag
    current.currentCRAEntryID = data
  },
  saveValidation: function() {
    const current = this
    var errors = []
    if (current.adaptationTypeCode == "") {
      var errorMessage = {}
      errorMessage.errorTitle = "Adaptation Type"
      errorMessage.errorBody = current.errorMsgDictionary.noAdaptationType
      errors.push(errorMessage)
    }
    if (current.ownerResponsible == "") {
      var errorMessage = {}
      errorMessage.errorTitle = "Owner Responsible"
      errorMessage.errorBody = current.errorMsgDictionary.noOwnerResponsible
      errors.push(errorMessage)
    }
    if (current.textAdaptActions == "") {
      var errorMessage = {}
      errorMessage.errorTitle = "Proposed adaptation action details and design/operational plan reference"
      errorMessage.errorBody = current.errorMsgDictionary.noAdaptationActions
      errors.push(errorMessage)
    }
    if (current.selectedImplementationTiming == "") {
      var errorMessage = {}
      errorMessage.errorTitle = "Implementation timing"
      errorMessage.errorBody = current.errorMsgDictionary.noImplementationTiming
      errors.push(errorMessage)
    }
    
    return errors
  },
  submitAdaptActions: function(){
    const current = this
    var errors = current.saveValidation();
    if (errors.length > 0) {
      errors.forEach(item=>{
        current.$notify({
          group: 'errors',
          title: item.errorTitle,
          type: 'error',
          text: item.errorBody,
          width: 500,
          duration: -1
        })
      })
    }
    else {
      var data = {
        craAdaptationActionId: current.currentAdaptationActionID,
        climateRiskEntryId: current.currentCRAEntryID,
        adaptationTypeCode: current.adaptationTypeCode,
        adaptationActions: current.textAdaptActions,
        ownerResponsible: current.ownerResponsible,
        implementationTiming: current.selectedImplementationTiming,
        isImplemented: current.isActionImplemented
      }
      var newAdaptationActionURL = baseApiUrl + "craAdaptationAction"
      var config  = {
        responseType: 'json'
      }
      axios.put(newAdaptationActionURL, data, config).then(response=>{
        current.$store.dispatch("mapModule/fetchCRAEntries").then(()=>{
          this.$store.commit('mapModule/setCurrentProjectCRAEntries', current.projectId)
          this.$notify({
              group: 'successNotifications',
              title: "Adaptation actions Update",
              type: 'success',
              text: "Update to the adaptation actions completed.",
              width: 500,
              duration: 1500
          })
          
        })
        .catch((err)=>{
              this.$notify({
                  group: 'errors',
                  title: "No Update done",
                  type: 'error',
                  text: "No Update to the adaptation actions",
                  width: 500,
                  duration: 1500
              })
          })
        current.openEngDesAdaptationModalFlag = false
      })
      
    }

  },
  viewAdaptationAction: function(craID,adaptID,adaptItem){
    const current = this
    current.adaptationItemId = adaptID
    current.openEngDesAdaptationModalFlag = !current.openEngDesAdaptationModalFlag
    current.currentAdaptationActionID = adaptItem.id
    current.currentCRAEntryID = craID
    current.adaptationTypeCode = current.adaptationTypes.filter(obj=>obj.id == adaptItem.adaptation_type_id)[0].code
    current.textAdaptActions = adaptItem.adaptation_actions
    current.ownerResponsible = adaptItem.owner_responsible
    current.selectedImplementationTiming = adaptItem.implementation_timing
    current.isActionImplemented = adaptItem.is_implemented
  },
  addAttendee(){
    const current = this
    if(current.textAttendees){
      current.stage4AttendeesList.push({
        id: null,
        projectId: current.projectId,
        code: "stage_4",
        attendees: current.textAttendees,
        date: new Date().toISOString().substring(0,10)
      })

    }
    current.textAttendees = ""
  },
  saveAttendees(){
    const current = this
    var data = current.stage4AttendeesList.filter(obj=>obj.id == null)
    if(data.length > 0){
      // save
      this.$store.dispatch('mainModule/updateProjectAssessmentStakeholders', data).then(()=>{
        current.$store.dispatch("mainModule/fetchProjectAssessmentStakeholders", current.projectId).then(()=>{
            this.$notify({
                group: 'successNotifications',
                title: "Success",
                type: 'success',
                text: "Successfully added attendees",
                width: 500,
                duration: 3000
            })
            
        })
      })
    }
    current.openAttendeesModalFlag = false
  },
  cancelAttendees(){
    const current = this
    setTimeout(() => {
        current.stage4AttendeesList = []
    }, 100);
    setTimeout(() => {
        current.stage4AttendeesList = current.$store.state.mainModule.projectAssessmentStakeholdersList.filter(obj => obj.craStageCode == "stage_4");
    }, 100);
    current.textAttendees = ""
    current.openAttendeesModalFlag = false
  }
},
mounted() {
  const current = this
  current.$store.dispatch('mapModule/fetchCRAEntries')
  // this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}
},
beforeCreate(){
  this.currentCRAEntry = {...this.$store.state.mapModule.currentProjectCRAEntryList}
  // this.currentCRAEntry = this.currentCRAEntry.map(item => ({...item, isEdit: false}))
  this.preAssessmentItems = this.filteredList
}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive {
    max-height:82vh;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.bottomright {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.btn-orange {
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.btn-orange:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #E66200 !important;
  border-color: #E66200 !important;
}
.page-link {
  color: #E66200 !important;
}
.page-link:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 98, 0, 0.25) !important;
}
.text-orange {
    color: rgb(255, 136, 0);
}
.materiality-high {
    color: #fff;
    background-color: orangered !important;
}

.materiality-medium {
    color: #fff;
    background-color: orange !important;
}

.materiality-low {
    color: #fff;
    background-color: lightsalmon !important;
}
.table-th-border-custom {
  border: 1px solid #dee2e6;
}
.table-th-border-custom-left {
  border-left: 1px solid #dee2e6;
}
.table-th-border-custom-right {
  border-right: 1px solid #dee2e6;
}
.hideCustom {
  display: none;
}
</style>